<template>
  <div class="profile-corner ml-2">
    <b-button
      size="sm"
      class="button-export-style"
      title="Verrouiller / Deverrouiller"
      @click="changeLockAction"
      :class="className"
      :disabled="loading"
    >
      <div class="spinner-border" role="status" v-if="loading">
        <span class="sr-only">Loading...</span>
      </div>
      <font-awesome-icon icon="lock" v-else
    /></b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    className: { default: '' },
    grouped: { default: null }
  },
  data() {
    return {
      loading: false,
      error: null
    };
  },
  computed: {},
  methods: {
    ...mapActions(['changeLockProjectsTh']),
    async changeLockAction() {
      this.loading = true;
      await this.changeLockProjectsTh(this.grouped);
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.spinner-border {
  height: 15px;
  width: 15px;
}
</style>
